import { Dispatch, SetStateAction, useState } from 'react';

import {
	getStateFromLocalStorage,
	setStateToLocalStorage,
} from '@/src/modules/common/utils/localStorage';

const useLocalStorageDispatch =
	<T extends object>(dispatch: Dispatch<SetStateAction<T>>, key: string) =>
	(value: T | ((prevState: T) => T)) => {
		if (typeof value === 'function') {
			dispatch((prevState) => {
				const newState = value(prevState);

				setStateToLocalStorage(newState as object, key);

				return newState;
			});
		} else {
			setStateToLocalStorage<T>(value, key);
			dispatch(value);
		}
	};

export const readOrSetDataFromStorage = <T>(key: string, initData?: T): T | undefined => {
	const data = getStateFromLocalStorage<T>(key);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (!data || (initData?.version && data.version !== initData.version)) {
		return initData ?? undefined;
	} else {
		return data;
	}
};

const useLocalStorageState = <T>(
	key: string,
	initData?: T | undefined,
): [T | undefined, Dispatch<SetStateAction<T>>] => {
	const [state, setState] = useState<T | undefined>(readOrSetDataFromStorage(key, initData));

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const dispatcher = useLocalStorageDispatch(setState, key);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return [state, dispatcher];
};

export { useLocalStorageState };
