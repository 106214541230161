export type Route = {
	path: string;
	private: boolean;
};

export enum Routes {
	Home = 'home',

	WorkspaceHome = 'workspace',
	Dashboard = 'dashboard',
	Settings = 'settings',
	Support = 'support',
	Company = 'company',
	CreateWorkspace = 'workspace/create',
	EditWorkspace = 'workspace/edit',

	WorkspaceEvents = 'events',
	CreateEvent = 'events/create',

	WorkspaceUsers = 'users',
	WorkspaceProjects = 'projects',
	WorkspaceVenues = 'venues',

	Team = 'team',

	WorkspaceUserDetails = 'users/details',

	Event = 'event',
	EventStatus = 'event/status',
	EventReport = 'event/report',
	EventConfig = 'event/config',
	EventTeam = 'event/team',
	EventIntegrations = 'event/integrations',
	EventSchedule = 'event/schedule',
	EventDesign = 'event/design',
	EventUsers = 'event/users',
	EventUserDetails = 'event/users/details',
	EventActivities = 'event/activities',
	EventPublications = 'event/publications',
	EventRatings = 'event/ratings',
	EventEdit = 'event/edit',

	EventSupportCalendar = 'event/support-calendar',
	EventScheduleLive = 'event/schedule-live',
}

export enum RouteParts {
	WorkspaceId = 'workspace',
	EventId = 'event',
	UserId = 'user',
	ProjectId = 'projects',
}
