import React, { FC, useEffect, useState } from 'react';

import { AuthRolesService } from '@/src/modules/auth/services/authRoles.service';
import { Routes } from '@/src/modules/common/routes/common.routes.types';
import { useWorkspaces } from '@/src/modules/workspaces/hooks/useWorkspaces';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { Button } from 'primereact/button';

interface CreateWorkspaceButtonProps {
	mode?: 'link' | 'button';
}

export const CreateWorkspaceButton: FC<CreateWorkspaceButtonProps> = ({ mode }) => {
	const t = useTranslations('sidebar');

	const router = useRouter();
	const { workspaces, isLoading } = useWorkspaces();
	const [canShow, setCanShow] = useState(false);

	useEffect(() => {
		if (!workspaces) {
			return;
		}
		const sub = new AuthRolesService().me?.sub;
		if (!sub) {
			return;
		}

		const can = !workspaces.some((w) => w.createdBy === sub);

		setCanShow(can);
	}, [workspaces]);

	if (isLoading) {
		return null;
	}

	if (!canShow) {
		return null;
	}
	if (!mode || mode === 'button') {
		return (
			<Button
				label={t('add-workspace')}
				severity="info"
				icon="pi pi-plus"
				className="w-14rem h-3rem"
				onClick={() => {
					router.push(`/${Routes.CreateWorkspace}/`);
				}}
			/>
		);
	}

	return (
		<Link className="text-primary text-3xl" href={`/${Routes.CreateWorkspace}/`}>
			{t('add-workspace')}
		</Link>
	);
};
