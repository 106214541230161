import React, { FC, useState } from 'react';

import {
	COORDINATOR_ROLE,
	EDITOR_ROLE,
	GLOBAL_ADMIN_ROLE,
	ONLINE_ADMIN_ROLE,
	ONLINE_EDITOR_ROLE,
	ONLINE_TEAM_ROLE,
	REGULAR,
	SRM_ADMIN,
	SRM_USER_ROLE,
	SRM_VIEWER,
	SUPPORT_ROLE,
} from '@/src/modules/auth/models/auth.models';
import { Authorities } from '@/src/modules/common/utils';
import { Button } from 'primereact/button';

export const LocalLoginSelectAuthorities: FC<{
	applyButtonTitle: string;
	onApply?: (authorities: Authorities) => void;
}> = ({ applyButtonTitle, onApply }) => {
	const authorities = [
		REGULAR,
		SUPPORT_ROLE,
		SRM_USER_ROLE,
		SRM_ADMIN,
		EDITOR_ROLE,
		SRM_VIEWER,
		COORDINATOR_ROLE,
		ONLINE_ADMIN_ROLE,
		ONLINE_EDITOR_ROLE,
		ONLINE_TEAM_ROLE,
		GLOBAL_ADMIN_ROLE,
	] as Authorities;

	const [checkedAuthorities, setCheckedAuthorities] = useState<Authorities>([]);

	return (
		<div className="flex flex-column gap-4">
			{authorities.map((authority) => (
				<label
					key={authority}
					htmlFor={`cb_${authority}`}
					className="flex align-items-center cursor-pointer"
				>
					<input
						id={`cb_${authority}`}
						name={`cb_${authority}`}
						type="checkbox"
						className="cursor-pointer"
						checked={checkedAuthorities.includes(authority)}
						onChange={({ target: { checked } }) => {
							if (checked) {
								setCheckedAuthorities([...checkedAuthorities, authority]);
							} else {
								setCheckedAuthorities(
									checkedAuthorities.filter((c) => c !== authority),
								);
							}
						}}
					/>

					{authority}
				</label>
			))}

			<div className="flex gap-4">
				<Button
					severity="success"
					size="small"
					onClick={() => onApply?.(checkedAuthorities)}
				>
					{applyButtonTitle}
				</Button>
			</div>
		</div>
	);
};
