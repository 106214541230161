import { AccessTokenValue } from '@/src/modules/auth/token/AccessToken';
import { getAccessTokenFixtureFromCookies } from '@/src/modules/auth/utils/auth.utils';
import { parseJwt } from '@/src/modules/common/utils';

export class AccessTokenVerifier {
	public readonly raw?: string;

	constructor(public readonly serverRaw?: string) {
		this.raw = serverRaw ? serverRaw : getAccessTokenFixtureFromCookies();
	}

	public get hasValue() {
		return Boolean(this.raw);
	}

	public get isExpired() {
		const token = this.raw ? parseJwt<AccessTokenValue>(this.raw) : null;

		const exp = token?.exp;
		const expiredDate = new Date((exp || 0) * 1000).valueOf();

		const now = new Date().valueOf();

		return now > expiredDate;
	}
}
