export const GLOBAL_ADMIN_ROLE = 'admin';
export const SRM_USER_ROLE = 'srm_user';
export const COORDINATOR_ROLE = 'srm_coordinator';
export const EDITOR_ROLE = 'srm_editor';
export const ONLINE_ADMIN_ROLE = 'online_admin';
export const ONLINE_EDITOR_ROLE = 'online_editor';
export const ONLINE_TEAM_ROLE = 'online_team';
export const COMPANIES_ADMIN_ROLE = 'company_admin_';
export const SRM_VIEWER = 'srm_viewer';
export const ONLINE_DISCUSSION_ADMIN_ROLE = 'online_discussion_admin';
export const SUPPORT_ROLE = 'support';
export const SRM_ADMIN = 'srm_admin';
export const REGULAR = 'regular';
