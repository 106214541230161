import moment from 'moment/moment';

export interface LocalStorageCache<T> {
	date: Date;
	data?: T;
}

export const setStateToLocalStorage = <T extends object>(state: T, key: string) => {
	if (typeof window !== 'undefined') {
		try {
			localStorage.setItem(key, JSON.stringify(state));
		} catch (e) {
			return;
		}
	}
};

export const removeStateFromLocalStorage = (key: string) => {
	if (typeof window !== 'undefined') {
		try {
			localStorage.removeItem(key);
		} catch (e) {
			return;
		}
	}
};

export const getStateFromLocalStorage = <T>(key: string): T | undefined => {
	if (typeof window !== 'undefined') {
		try {
			const stringState = localStorage.getItem(key);
			if (!stringState) {
				return undefined;
			}
			return JSON.parse(stringState);
		} catch (e) {
			return undefined;
		}
	}
	return undefined;
};

export const getAndSetStateFromLocalStorageCached = async <T extends object>(
	key: string,
	func: () => Promise<T>,
): Promise<T | undefined> => {
	const data = getStateFromLocalStorage<LocalStorageCache<T>>(key);

	const isObsolete = !data?.date ? true : moment().diff(moment(data.date), 'minutes') > 5;
	// isLocalEnvironment ? false :

	if (isObsolete) {
		removeStateFromLocalStorage(key);

		const result = await func();

		if (result) {
			setStateToLocalStorage<LocalStorageCache<T>>(
				{
					date: new Date(),
					data: result,
				},
				key,
			);
		}
		return result;
	}
	return data?.data;
};
