export enum HttpCodes {
	OK = 200,
	CREATED = 201,

	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	NOT_ALLOWED = 405,
	NOT_ACCEPTABLE = 406,
	CONFLICT = 409,
	I_AM_TEAPOT = 418,

	SERVER_ERROR = 500,
}
