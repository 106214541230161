import { FC } from 'react';

import { SRM_MOCK_USERS } from '@/src/mocks/users/users.mock';
import {
	removeAccessTokenFixtureFromCookies,
	setAccessTokenFixtureFromObjectToCookies,
} from '@/src/modules/auth/utils/auth.utils';
import { Button } from 'primereact/button';

export const LocalLoginSelectUser: FC = () => {
	const handleSelectUser = (sub: string) => {
		const user = SRM_MOCK_USERS.find((u) => u.sub === sub);

		if (!user) {
			return;
		}

		removeAccessTokenFixtureFromCookies();
		setAccessTokenFixtureFromObjectToCookies(user);

		window.location.reload();
	};

	return (
		<div className="flex flex-column gap-2">
			{SRM_MOCK_USERS.map((user) => (
				<Button
					severity="info"
					size="small"
					key={user.sub}
					onClick={() => handleSelectUser(user.sub)}
				>
					<div className="flex flex-column justify-content-start">
						<span className="flex justify-content-start">
							{user.displayName ?? user.email}
						</span>
						<span style={{ fontSize: '10px' }}>({user.authorities.join(', ')})</span>
					</div>
				</Button>
			))}
		</div>
	);
};
