import {
	GLOBAL_ADMIN_ROLE,
	ONLINE_ADMIN_ROLE,
	ONLINE_TEAM_ROLE,
	SUPPORT_ROLE,
} from '@/src/modules/auth/models/auth.models';
import { AccessTokenValue } from '@/src/modules/auth/token/AccessToken';
import { AccessTokenVerifier } from '@/src/modules/auth/token/AccessTokenVerifier';
import { parseJwt } from '@/src/modules/common/utils';

export class AuthRolesService {
	private readonly accessToken: AccessTokenValue | null = null;
	private readonly authorities: string[] = [];

	constructor() {
		const accessTokenVerifier = new AccessTokenVerifier();

		if (accessTokenVerifier.raw) {
			this.accessToken = parseJwt<AccessTokenValue>(accessTokenVerifier.raw);
		}

		this.authorities = this.accessToken?.authorities ?? [];
	}

	get me() {
		return this.accessToken;
	}

	get isGlobalAdmin(): boolean {
		return this.authorities?.includes(GLOBAL_ADMIN_ROLE) ?? false;
	}

	get isOnlineAdmin(): boolean {
		return this.authorities.find((r) => r === ONLINE_ADMIN_ROLE) !== undefined;
	}

	public isAdminCoordinatorEditor(eventId: number): boolean {
		if (!eventId) {
			return false;
		}

		if (this.isGlobalAdmin) {
			return true;
		}

		return false;
	}

	public isModerator(eventId: number): boolean {
		if (!eventId) {
			return false;
		}

		return false;
	}

	get isGlobalOnlineTeam(): boolean {
		return this.authorities?.includes(ONLINE_TEAM_ROLE);
	}

	get isGlobalSupportTeam(): boolean {
		return this.authorities?.includes(SUPPORT_ROLE);
	}

	public isInRole(role: string) {
		return this.authorities?.find((r) => r === role) !== undefined;
	}

	public isInRolesAND(roles: string[]) {
		return roles.every(this.isInRole.bind(this));
	}

	public isInRolesOR(roles: string[]) {
		return roles.some(this.isInRole.bind(this));
	}
}
