import { useCallback, useEffect, useMemo } from 'react';

import { useLocalStorageState } from '@/src/modules/common/hooks/useLocalStorageState';
import { useWorkspaces } from '@/src/modules/workspaces/hooks/useWorkspaces';
import { WorkspaceEntity } from '@/src/modules/workspaces/models/workspaces.models';
import { useParams } from 'next/navigation';

interface UseWorkspaceFromContext {
	workspace?: WorkspaceEntity;
	workspaces?: WorkspaceEntity[];
	switchDefaultWorkspace: (workspaceId: string) => void;
	isLoading: boolean;
}

const DEFAULT_WORKSPACE_LOCALSTORAGE_KEY = 'jugru.org.workspaces.default';

export const useWorkspaceFromContext = (canGet = true): UseWorkspaceFromContext => {
	const { workspaceId: workspaceIdOrSlugFromUrl } = useParams<{
		workspaceId?: string;
	}>();

	const { workspaces, getByIdOrSlug, isLoading } = useWorkspaces(canGet);

	const [currentWorkspaceIdFromLS, setCurrentWorkspaceIdToLS] = useLocalStorageState<
		string | undefined
	>(DEFAULT_WORKSPACE_LOCALSTORAGE_KEY);

	const workspace = useMemo(() => {
		if (workspaceIdOrSlugFromUrl) {
			return getByIdOrSlug(workspaceIdOrSlugFromUrl);
		}
		return getByIdOrSlug(currentWorkspaceIdFromLS);
	}, [workspaces, workspaceIdOrSlugFromUrl, currentWorkspaceIdFromLS]);

	useEffect(() => {
		if (!workspaces) {
			return;
		}

		if (workspaces.length > 0) {
			if (currentWorkspaceIdFromLS) {
				const w = getByIdOrSlug(currentWorkspaceIdFromLS);

				if (!w) {
					setCurrentWorkspaceIdToLS(workspaces[0].id);
				}
			} else {
				setCurrentWorkspaceIdToLS(workspaces[0].id);
			}
		} else {
			setCurrentWorkspaceIdToLS(undefined);
		}
	}, [workspaces, currentWorkspaceIdFromLS]);

	const switchDefaultWorkspace = useCallback((workspaceId: string) => {
		setCurrentWorkspaceIdToLS(workspaceId);
	}, []);

	return useMemo(
		() => ({
			workspace,
			workspaces,
			switchDefaultWorkspace,
			isLoading,
		}),
		[workspace, workspaces, switchDefaultWorkspace, isLoading],
	);
};
