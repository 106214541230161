'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { ApiMultiResponse } from '@/src/modules/common/models/common.models';
import { workspacesActions } from '@/src/modules/workspaces/actions/workspaces.actions';
import { WorkspaceEntity } from '@/src/modules/workspaces/models/workspaces.models';
import { workspacesApiPath } from '@/src/modules/workspaces/routes/workspaces.api.routes';

export type UseWorkspaces = {
	workspaces?: WorkspaceEntity[];
	isLoading: boolean;
	reload: UseGETReloadFunction;
	getByIdOrSlug: (workspaceId?: string) => WorkspaceEntity | undefined;
};

export const useWorkspaces = (canGet = true): UseWorkspaces => {
	const [workspaces, setWorkspaces] = useState<WorkspaceEntity[]>();

	const { data, isLoading, reload } = useGET<ApiMultiResponse<WorkspaceEntity>, unknown>({
		endpoint: workspacesApiPath,
		canGet,
	});

	useEffect(() => {
		if (data?.data) {
			setWorkspaces(data.data.map(workspacesActions.addRights));
		}
	}, [data?.data]);

	const getByIdOrSlug = useCallback(
		(workspaceIdOrSlug?: string) =>
			workspaces?.find(
				(workspace) =>
					workspace.slug === workspaceIdOrSlug || workspace.id === workspaceIdOrSlug,
			),
		[workspaces],
	);

	return useMemo(
		() => ({
			workspaces,
			isLoading,
			reload,
			getByIdOrSlug,
		}),
		[workspaces, isLoading, reload, getByIdOrSlug],
	);
};
