export const SRM_EDITOR_USER = {
	sub: 'lk-oauth2|2279',
	user_name: 'yulia.lebedeva+6@jugru.org',
	displayName: 'Шесть Тест',
	profile: 'https://oh-my-duck-dev.jugru.org/users/z21Ad2',
	iss: 'lk',
	authorities: ['srm_user', 'srm_editor', 'regular'],
	picture: '',
	client_id: 'srm-client',
	lk_user_id: 2279,
	scope: ['regular'],
	lk_session_id: 156321,
	exp: 1737205754,
	lang: 'EN',
	jti: 'fcb94535-3290-4916-a92e-e4ff307306ba',
	email: 'yulia.lebedeva+6@jugru.org',
};

export const SRM_COORDINATOR_USER = {
	sub: 'lk-oauth2|714635',
	user_name: 'yulia.lebedeva+7@jugru.org',
	displayName: 'test Тест',
	profile: 'https://oh-my-duck-dev.jugru.org/users/qLPavk',
	iss: 'lk',
	authorities: ['srm_coordinator', 'srm_user', 'regular'],
	picture: '',
	client_id: 'srm-client',
	lk_user_id: 714635,
	scope: ['regular'],
	lk_session_id: 156322,
	exp: 1737205754,
	lang: 'RU',
	jti: 'bcc0645f-0750-413c-823e-50ee17b85e36',
	email: 'yulia.lebedeva+7@jugru.org',
};

export const SRM_MOCK_USERS = [SRM_EDITOR_USER, SRM_COORDINATOR_USER];
