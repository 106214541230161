'use client';

import React, { FC, useRef, useState } from 'react';

import { isLocalEnvironment } from '@/environment';
import { LocalLoginSelectAuthorities } from '@/src/modules/auth/components/LocalLogins/LocalLoginSelectAuthorities';
import { LocalLoginSelectUser } from '@/src/modules/auth/components/LocalLogins/LocalLoginSelectUser';
import { publicEndpointLogin } from '@/src/modules/auth/services/auth.api.service';
import { setAccessTokenFixtureFromAuthoritiesToCookies } from '@/src/modules/auth/utils/auth.utils';
import { useMounted } from '@/src/modules/common/hooks/useMounted';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

enum LocaleLoginEnum {
	Users = 'users',
	Manual = 'manual',
}

export type LoginProps = {
	linkName: string;
};

export const Login: FC<LoginProps> = ({ linkName }) => {
	const isMounted = useMounted();

	const returnUrl = isMounted ? window.location.href : '';

	const [localeLogin, setLocaleLogin] = useState<LocaleLoginEnum>(LocaleLoginEnum.Users);

	const op = useRef(null);

	if (isLocalEnvironment) {
		return (
			<>
				<a href="#" onClick={(e) => (op.current! as OverlayPanel).toggle(e)}>
					{linkName}
				</a>

				<OverlayPanel ref={op} showCloseIcon>
					<div className="flex gap-2">
						<Button
							severity="secondary"
							size="small"
							disabled={localeLogin === LocaleLoginEnum.Users}
							onClick={() => setLocaleLogin(LocaleLoginEnum.Users)}
						>
							users
						</Button>
						<Button
							severity="secondary"
							size="small"
							disabled={localeLogin === LocaleLoginEnum.Manual}
							onClick={() => setLocaleLogin(LocaleLoginEnum.Manual)}
						>
							authorities
						</Button>
					</div>

					{localeLogin === LocaleLoginEnum.Users && (
						<div className="mt-4" style={{ minWidth: '250px' }}>
							<LocalLoginSelectUser />
						</div>
					)}

					{localeLogin === LocaleLoginEnum.Manual && (
						<div className="mt-4" style={{ minWidth: '250px' }}>
							<LocalLoginSelectAuthorities
								applyButtonTitle={linkName}
								onApply={(authorities) => {
									setAccessTokenFixtureFromAuthoritiesToCookies(authorities);

									window.location.reload();
								}}
							/>
						</div>
					)}
				</OverlayPanel>
			</>
		);
	}

	return <a href={publicEndpointLogin(returnUrl)}>{linkName}</a>;
};
