import { getHostUrl, isLocalEnvironment } from '@/environment';
import { removeAccessTokenFixtureFromCookies } from '@/src/modules/auth/utils/auth.utils';
import { HttpCodes } from '@/src/modules/common/utils/http-codes';

const TENANT = 'm2m';

const baseURL = `${getHostUrl()}api/v2/auth`;

export const publicEndpointLogin = (returnPage?: string) =>
	`${baseURL}/login/${TENANT}/${returnPage ? `?redirect_uri=${returnPage}` : ``}`;

const privateEndpoints = {
	silentLogin: `login/${TENANT}?prompt=none`,
	logout: `logout?tenant=${TENANT}`,
	verify: `verify/${TENANT}`,
};

export class AuthApiService {
	public logout(): Promise<Response> | undefined {
		if (isLocalEnvironment) {
			removeAccessTokenFixtureFromCookies();

			window.location.reload();

			return;
		}

		return fetch(`${baseURL}/${privateEndpoints.logout}`);
	}

	public verify(): Promise<boolean> {
		const endpoint = `${baseURL}/${privateEndpoints.verify}`;

		return fetch(endpoint, { credentials: 'include' })
			.then((response) => response.status !== HttpCodes.UNAUTHORIZED)
			.catch((error) => error.response.status !== HttpCodes.UNAUTHORIZED);
	}

	public silentLogin(): Promise<Response> {
		const endpoint = `${baseURL}/${privateEndpoints.silentLogin}`;

		return fetch(endpoint, { credentials: 'include' });
	}
}
